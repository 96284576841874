import './index.scss'
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import Swiper from 'swiper/swiper-bundle.min.js'

//banner轮播
var bannerswiper = new Swiper(".index-banner .mySwiper", {
  // loop: true,
  speed: 800,
  effect : 'fade',
  autoplay: {
    delay: 6000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {// 分页
    el: ".index-banner .swiper-pagination",
    clickable: true,
  },
})

$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //滚动高度
  let viewH = $(window).height(); //可见高度
  let fixed1Top = $('.index-pro-cont').offset().top - viewH + $('.pro1').outerHeight();
  let fixed1Btm = $('.index-pro-cont').offset().top - viewH + $('.index-pro-cont').outerHeight();

  if (scroH <= fixed1Top) {
    $('.index-pro-cont .fixed-bg').removeClass('fixed')
  }else if (scroH > fixed1Top && scroH < fixed1Btm) {
    $('.index-pro-cont .fixed-bg').addClass('fixed').removeClass('abs')
  }else{
    $('.index-pro-cont .fixed-bg').addClass('abs').removeClass('fixed')
  }
});

$(document).ready(function(){
  setPosition()
})
$(window).resize(function(){
  setPosition()
})
function setPosition(){
  let hengH = $('.index-pro-cont .fixed-bg').height()
  let pro1H = $('.pro1').outerHeight()

  $('.index-pro-cont .fixed-bg').css('top', (pro1H - hengH) +'px')
}